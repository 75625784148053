
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { RawLocation } from 'vue-router'
import { ReservationAudit } from '@/models/dto/ReservationAudit'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import ReservationAuditSidebarDetail from '@/components/ReservationAuditSidebarDetail.vue'
import client from '@/services/reservationAudit'
import sidebar from '@/store/modules/sidebar'

@Component({
  components: {
    CUCollectionTable,
  },
})
export default class ReservationAuditsList extends mixins(DateMixin) {
  tableView = client.tableView
  tableItemCount = 0
  search = ''

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleSelectedReservationAudit(reservationAudit: ReservationAudit): void {
    sidebar.push({
      title: 'Reservation Audit',
      component: ReservationAuditSidebarDetail,
      props: { reservationAudit },
    })
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '2ce7ad28-3512-49a3-bce9-16821680dde7',
      text: 'ID',
      value: 'reservationAuditId',
      type: 'link',
      clickEvent: 'reservation-audits:reservation-audit-selected',
    },
    {
      _t_id: '5128651b-30a2-4c94-b8eb-9ab4e3485f62',
      text: 'Referral ID',
      value: 'reservation/referralId',
    },
    {
      _t_id: 'd9ffa70a-fd34-4b95-969e-5ebcf8794fc6',
      text: 'Res. ID',
      value: 'managedId',
      columnSlotComponent: CUDataTableLink,
      linkLocation: (row: ReservationAudit): RawLocation => ({
        name: 'reservations.detail',
        params: { id: row.reservationId?.toString() },
      }),
    },
    {
      _t_id: 'cf5a5d93-8e30-4b2e-9f3b-3f00a4fa1441',
      text: 'User',
      value: 'user/name',
      computedText: (row: ReservationAudit): string =>
        `${row.user?.firstName || ''} ${row.user?.lastName || ''}`,
    },
    {
      _t_id: 'a8a075d4-427c-4f74-ad51-355f15edb87a',
      text: 'Company',
      value: 'companyName',
    },
    {
      _t_id: '20bc6396-293a-461a-b496-c740f7c0245f',
      text: 'Action Type',
      value: 'actionType',
    },
    {
      _t_id: 'c11ad5af-9e8e-4ceb-ac1f-5f4115753853',
      text: 'Date Time',
      value: 'datetime',
      computedText: (row: ReservationAudit): string =>
        this.formatMediumDate(row.datetime),
    },
  ]

  mounted(): void {
    EventBus.$on(
      'reservation-audits:reservation-audit-selected',
      this.handleSelectedReservationAudit
    )
  }

  beforeDestroy(): void {
    EventBus.$off(
      'reservation-audits:reservation-audit-selected',
      this.handleSelectedReservationAudit
    )
  }
}
